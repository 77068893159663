import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, Route, Switch, RouteProps } from "react-router-dom";

// layout HOC
import withLayout from "../layouts/withLayout";
import applicationInsights from "../global/applicationInsights";
import NotFound from "../pages/error/PageNotFound";

import { IAppRoute } from "./types";
import { IReduxState } from "../redux/types";
import PageNotFound from "../pages/error/PageNotFound";
import PrivateRoute from "./PrivateRoute";
import authenticationStore from "../redux/auth/authenticationStore";
import Loading from "../components/Loading";
import { getCallIdFromToken, isLoanUser } from "./loan-call";

interface IPublicProps {}
interface IReduxProps extends IPublicProps {
  routes: IAppRoute[];
  authenticated: boolean;
  autoLoginComplete: boolean;
  currentPath: string;
}

class Routes extends Component<IReduxProps> {
  public shouldComponentUpdate(next: IReduxProps) {
    return (
      next.routes !== this.props.routes ||
      next.authenticated !== this.props.authenticated ||
      next.autoLoginComplete !== this.props.autoLoginComplete
    );
  }

  public render() {
    const ToLogin = () => {
      authenticationStore.preLoginLocation = this.props.currentPath;
      return <Redirect to="/auth/login" />;
    };

    if (!this.props.routes || this.props.routes.length === 0) {
      return <PageNotFound />;
    }

    if (!this.props.autoLoginComplete) return <Loading />;

    return (
      <Switch>
        {this.props.routes.map((route, index) => {
          const wrapper = (x) =>
            withLayout(
              route,
              applicationInsights.trackComponent(x, route.name)
            );

          let RouteComponent: React.ComponentType<
            RouteProps & { authenticated: boolean }
          >;
          if (route.allowAnonymous) RouteComponent = Route;
          else RouteComponent = PrivateRoute;

          return (
            route.component && (
              <RouteComponent
                key={index}
                path={route.path}
                exact={route.exact}
                authenticated={this.props.authenticated}
                component={wrapper((layoutProps: any) => {
                  return <route.component {...layoutProps} />;
                })}
              />
            )
          );
        })}

        {this.props.authenticated && (
          <Route path="/" exact>
            {isLoanUser() ? (
              <Redirect
                to={`/call/${getCallIdFromToken(
                  authenticationStore.sessionToken
                )}`}
              />
            ) : (
              <Redirect to="/home" />
            )}
          </Route>
        )}
        <Route path="/">
          {!this.props.authenticated ? <ToLogin /> : <NotFound />}
        </Route>
      </Switch>
    );
  }
}

const ConnectedRoutes = connect(
  (state: IReduxState, ownProps: IPublicProps): IReduxProps => {
    return {
      ...ownProps,

      authenticated: state.auth.authenticated,
      autoLoginComplete: state.auth.autoLoginComplete,
      currentPath:
        state.router.location.pathname +
        (state.router.location.search ? state.router.location.search : ""),
      routes: state.navigation.routes,
    };
  }
)(Routes);

export default ConnectedRoutes;
