import React, {Component} from 'react';

import './Loading.scss';

interface IState {
    show: boolean;
}
/**
 * Renders the preloader
 */
class Loading extends Component<{ text?: string }, IState> {
    constructor(props, state) {
        super(props, state);

        this.state = { show: false };
    }
    private time: NodeJS.Timeout;
    public componentDidMount() {
        this.time = setTimeout(() => this.setState({show: true}), 200);
    }
    public componentWillUnmount() {
        if (this.time)
            clearTimeout(this.time);
    }
    public render() {
        return this.state.show ? (
            <div className="preloader" aria-busy="true" aria-label="Bezig met laden">
                {/* {this.props.text && <div className="status-text">{this.props.text}</div>} */}
                <div className="center-box">
                    <div className="status">
                        {this.props.text}
                        <div className="bouncing-loader">
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                </div>
            </div>
        ) : <React.Fragment/>;
    }
}

export default Loading;
