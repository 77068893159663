import { AxiosError, AxiosResponse } from 'axios';

import applicationInsights from '../../global/applicationInsights';
import { getCurrentStore } from '../../redux/store';
import { ApiError } from './ApiError';
import { raiseException, raiseApiException } from '../../redux/notifications/actions';

export interface ICallerOptions {

    /** How to handle rejections on the promise
     * immediate: dispatches raiseException; the caller does not have to handle the error
     * auto: (default) dispatches a raiseException after .5 seconds unless cancelApiException or raiseException is called from some an other source
     * never: no dispatch, the caller should handle the error
     */
    raiseException?: 'immediate' | 'auto' | 'never';
    /** How are 404 responses handled, 
     * error: (default) keep the rejection, 
     * null: return null 
     */
    notFoundHandling?: 'error' | 'null';
    /** How are empty responses handled, 
     * null: (default) return null 
     * any other: reject the promise with this value as the error, 
     */
    nullResultError?: string;
    fullResponse?: boolean;
    allowRetry?: boolean;
}
export interface IApiOptions<T> extends ICallerOptions {
    forceResolveConflict?: () => Promise<T>;
}
const defaultCallerOptions: IApiOptions<any> = {
    fullResponse: false,
    notFoundHandling: 'error',
    nullResultError: null,
    raiseException: 'auto',
    allowRetry: true,
};
interface IErrorResponse {
    message: string;
    title?: string;
    isApplicationError: boolean;
    isUserError: boolean;
    reference: string;
    code: number;
    errors: { [field: string]: string[] }; // expected for 400 only
}

function requestWrapper<T>(call: () => Promise<AxiosResponse<T>>, callOptions: IApiOptions<T>): Promise<T> {
    return requestWrapperFull(call, callOptions)
        .then((response) => response && response.data);
}

function innerExecute<T>(call: () => Promise<AxiosResponse<T>>, options: IApiOptions<T>) {
    return applicationInsights.trackRequest(call())
        .then((data: any) => {
            if (data.isAxiosError)
                return Promise.reject(data);
            else 
                return Promise.resolve(data.response || data);
        })
        .catch((error: AxiosError) => {
            if (!error.response || !error.isAxiosError) {
                return Promise.reject(new ApiError(
                    'Server operation failed: ' + error.message,
                    false,
                    JSON.stringify({ message: error.message, stack: error.stack, error }),
                    true,
                    null));
            }

            const detail = {
                request: {
                    body: error.request.body,
                    method: error.request.method,
                    url: error.request.url,
                },
                response: {
                    content: error.response.data,
                    status: error.response.status,
                    statusText: error.response.statusText,
                },
            };

            // For known responses extract available information
            if (error.response
                && error.response.data
                && (error.response.data.message || error.response.data.title)
                && (
                    [400, 401, 403, 404].indexOf(error.response.status) !== -1
                    || error.response.data.isApplicationError
                )
            ) {

                if (error.response.status === 404 && options.notFoundHandling === 'null')
                    return Promise.resolve(null);

                const errorResponse: IErrorResponse = error.response.data;

                const errors: Array<{ field: string, messages: string[] }> = [];
                if (error.response.status === 400) {
                    for (const key in error.response.data.errors) {
                        if (typeof (errorResponse.errors[key]) === 'object' && typeof (errorResponse.errors[key].length) === 'number')
                            errors.push({ field: key, messages: errorResponse.errors[key] });
                    }
                }

                return Promise.reject(new ApiError(
                    errorResponse.message || errorResponse.title,
                    errorResponse.isUserError || (error.response.status === 400 && errors.length > 0),
                    errors.length > 0 ? errors.map((x) => `${x.field}: ${x.messages.join(', ')}`).join('\r\n') : JSON.stringify(detail),
                    false,
                    errors));
            }
            if (error.response.status === 409 && options.forceResolveConflict) {
                return options.forceResolveConflict();
            }

            return Promise.reject(new ApiError(
                'Error during server operation: ' + error.response.statusText,
                false,
                JSON.stringify(detail),
                error.response.status === 503 || error.response.status === 0,
                null));
        });
}

function requestWrapperFull<T>(call: () => Promise<AxiosResponse<T>>, callOptions: IApiOptions<T>): Promise<AxiosResponse<T>> {
    const options = { ...defaultCallerOptions, ...callOptions };

    return innerExecute(call, options)
        .catch((error: any) => {
            if (error && error.isTransient === true)
                return innerExecute(call, options)
                    .catch((error2) => Promise.reject(new ApiError(error.message, error.isUserError, error.detail + '\r\n\r\nRetry:\r\n' + error2, error.isTransient, error.fieldErrors)));

            return Promise.reject(error);
        })
        .catch((error: any) => {
            if (error instanceof ApiError) {
                if (options.raiseException === 'immediate')
                    getCurrentStore().dispatch(raiseException(error, error.message));
                else if (options.raiseException === 'auto')
                    getCurrentStore().dispatch(raiseApiException(error));

            }
            return Promise.reject(error);
        })
        .then((response: AxiosResponse<T>) => {
            if (response.status === 204 && options.nullResultError) {
                return Promise.reject(new ApiError(
                    options.nullResultError,
                    true,
                ));
            }

            return response;
        });
}

export default requestWrapper;