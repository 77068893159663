// @flow
import { all } from 'redux-saga/effects';

import authSaga from './auth/saga';
import navigationSaga from './navigation/saga';
import notificationSaga from './notifications/saga';

export default function* rootSaga(getState: any): any {
    yield all([
        authSaga(),
        navigationSaga(),
        notificationSaga(),
    ]);
}
