import { IAppRoute } from "../../route/types";
import * as c from "./constants";
import { IMenuItem } from "./reducers";


export const navigationRoutesChange = (routes: IAppRoute[]) => ({
    payload: routes,
    type: c.NAV_ROUTES_CHANGE,
});

export const navigationMenuChange = (menu: IMenuItem[]) => ({
    payload: menu,
    type: c.NAV_MENU_CHANGE,
});

export const navigationActiveItemsChange = (active: string[]) => ({
    payload: active,
    type: c.NAV_ACTIVE_ITEMS_CHANGE,
});