import { IJwtToken } from "../redux/auth/saga";
import jwt_decode from "jwt-decode";

function readToken(token: string, ignoreInvalid: boolean = false): IJwtToken {
  try {
    return jwt_decode<IJwtToken>(token);
  } catch (e) {
    if (ignoreInvalid) return null;
    throw new Error("Invalid Token");
  }
}

export function isLoanUser(): boolean {
  const token = sessionStorage.getItem("cached-token");
  const decoded = readToken(token, true);

  if (decoded && decoded["fno-callid"]) {
    const callId = decoded["fno-callid"];
    console.log(`found callid: ${callId}`);

    return true;
  }
  console.log("no callid found");

  return false;
}

export function getCallIdFromToken(token: string): string {
  const decoded = readToken(token);

  if (decoded && decoded["fno-callid"]) {
    const callId = decoded["fno-callid"];
    return callId;
  }
  return null;
}
