import { all, fork, put, select, takeEvery } from 'redux-saga/effects';
import { AUTH_SUCCESS, AUTH_LOGOUT } from '../auth/constants';
import { raiseException } from '../notifications/actions';
import { IReduxState } from '../types';
import { IAppRoute } from '../../route/types';
import { IMenuItem } from './reducers';
import allRoutes from '../../route/all-routes';
import { navigationMenuChange, navigationRoutesChange, navigationActiveItemsChange } from './actions';
import { flattenRoutes } from './flattenRoutes';

const getActivatedMenuItemIds = (menuItems: IMenuItem[]) => {
    let matchingItems: string[] = [];

    for (const menuItem of menuItems) {
        let match = false;
        if (menuItem.path.indexOf(':') === -1) {
            match = window.location.pathname.indexOf(menuItem.path) === 0;
        }
        else {
            const path = menuItem.path;
            if (window.location.pathname.indexOf(path.substring(0, path.indexOf(':'))) === 0) {
                const splitW = window.location.pathname.split('/');
                const splitP = path.split('/');
                if (splitW.length >= splitP.length) {
                    for (let cnt = 0; cnt < splitP.length; cnt++) {
                        if (splitP[cnt].substr(0, 1) !== ':' && splitW[cnt] !== splitP[cnt])
                            continue;
                    }
                    match = true;
                }
            }
        }

        if (match) {
            matchingItems.push(menuItem.id);

            if (menuItem.children) {
                matchingItems = [...matchingItems, ...getActivatedMenuItemIds(menuItem.children)];
            }
        }
    }

    return matchingItems;
};
function filterRoutes(availableRoutes: IAppRoute[], roles: string[], authenticated: boolean): IAppRoute[] {
    const filtered = availableRoutes
        .filter((item) => {
            if (!authenticated)
                return item.allowAnonymous;

            if (item.roles && item.roles.length !== 0) {
                if (!roles || !item.roles.some((v) => roles.indexOf(v) !== -1))
                    return false;
            }
            return true;
        })
        .map((item) => {
            if (item.children) {
                item = {
                    ...item,
                    children: filterRoutes(item.children, roles, authenticated),
                };

                // Remove empty items with no own component
                if (item.children && item.children.length === 0 && !item.component)
                    return null;
            }

            return item;
        })
        .filter((v) => v !== null);

    return filtered as IAppRoute[];
}

let uuid = 1;
function extractMenu(routes: IAppRoute[], parentId: number = 0): IMenuItem[] {
    return routes.filter((item) => item.showInMenu)
        .map((item): IMenuItem => {
            uuid += 1;

            return {
                id: uuid.toString(),
                parentId: parentId.toString(),

                header: item.header || item.name,
                icon: item.icon,
                name: item.name,
                path: item.path,

                children: item.children ? extractMenu(item.children, uuid) : [],
            };
        });
}
function* initMenu() {
    try {
        const { roles, authenticated } = yield (select((state: IReduxState) => ({ roles: state.auth.userRoles, authenticated: state.auth.authenticated })));
        const allowedRoutes = filterRoutes(allRoutes, roles, authenticated);
        yield put(navigationRoutesChange(flattenRoutes(allowedRoutes)));
        const menuItems = extractMenu(allowedRoutes);

        const activatedMenuItemIds = getActivatedMenuItemIds(menuItems);

        yield put(navigationMenuChange(menuItems));
        yield put(navigationActiveItemsChange(activatedMenuItemIds));
    }
    catch (e) {
        yield put(raiseException(e, 'Failed to load the application menu', 'danger'));
    }
}

function* updateFromRouter() {
    const { menuItems, active } = yield (select((state: IReduxState) => ({ menuItems: state.navigation.menuItems, active: state.navigation.activatedMenuItemIds })));
    const activatedMenuItemIds = getActivatedMenuItemIds(menuItems);

    if (active.length !== activatedMenuItemIds.length || activatedMenuItemIds.some((v) => active.indexOf(v) === -1))
        yield put(navigationActiveItemsChange(activatedMenuItemIds));

}
function* watchMenuInit(): any {
    yield takeEvery([AUTH_SUCCESS, AUTH_LOGOUT], initMenu);
}
function* watchUpdateFromRouter(): any {
    yield takeEvery("@@router/LOCATION_CHANGE", updateFromRouter);
}

function* navigationSaga(): any {
    yield all([
        fork(watchMenuInit),
        fork(watchUpdateFromRouter),
    ]);
}

export default navigationSaga;
