import { IAppRoute } from "../../route/types";
import { NAV_ROUTES_CHANGE, NAV_MENU_CHANGE, NAV_ACTIVE_ITEMS_CHANGE } from "./constants";
import { flattenRoutes } from "./flattenRoutes";
import allRoutes from "../../route/all-routes";

export interface IMenuItem {
    badge?: { variant: string, text: string };
    children: IMenuItem[];
    id: string;
    icon?: string;
    name: string;
    header: string;
    parentId: string;
    path: string;
}

export interface INavigationState {
    menuItems: IMenuItem[];

    activatedMenuItemIds: string[];
    routes: IAppRoute[];
}
export const initialState: INavigationState = {
    activatedMenuItemIds: [],
    menuItems: [],
    routes: flattenRoutes(allRoutes).filter((x) => x.allowAnonymous),
};

const Reducer = (state = initialState, action: any) => {
    switch (action.type) {
        case NAV_ROUTES_CHANGE:
            return {
                ...state,
                routes: action.payload,
            };
        case NAV_MENU_CHANGE:
            return {
                ...state,
                menuItems: action.payload,
            };
        case NAV_ACTIVE_ITEMS_CHANGE:
            return {
                ...state,
                activatedMenuItemIds: action.payload,
            };

        default:
            return state;
    }
}

export default Reducer