import React, { Suspense } from "react";

import Loadable from '@yeutech-lab/react-loadable';
import { withRouter } from "react-router-dom";
import { IReduxState } from "../redux/types";
import { connect } from "react-redux";
import { IAppRoute } from "../route/types";

const loading = () => <div className="page-load-indicator">Loading...</div>;

const layouts = {
    'auth': Loadable({
        loader: () => import('./Auth'),
        render(loaded: any, props: any) {
            const Component = loaded.default;
            return <Component {...props} />;
        },
        loading
    }),
    'preauth': Loadable({
        loader: () => import('./PreAuth'),
        render(loaded: any, props: any) {
            const Component = loaded.default;
            return <Component {...props} />;
        }, 
        loading
    }),
    'recover':  Loadable({
        loader: () => import('./Recover'),
        render(loaded: any, props: any) {
            const Component = loaded.default;
            return <Component {...props} />;
        }, 
        loading
    }),
    'default': Loadable({
        loader: () => import('./Default'),
        render(loaded: any, props: any) {
            const Component = loaded.default;
            return <Component {...props} />;
        },
        loading
    }),
    'minimal': Loadable({
        loader: () => import('./Minimal'),
        render(loaded: any, props: any) {
            const Component = loaded.default;
            return <Component {...props} />;
        },
        loading
    })
}
export type LayoutType = 'auth' | 'preauth' | 'recover' | 'minimal' | { type: 'default', props: { wrapClass: string } };

const noProps = {};
const withLayout = (route: IAppRoute, WrappedComponent: any) => {

    interface IHOCProps {
        authenticated: boolean;
    }

    class HOC extends React.Component<IHOCProps> {
        private getLayout() {
            const layoutType = !route.layout ? 'default' : typeof(route.layout) === 'string' ? route.layout : route.layout.type;
            const layoutProps = !route.layout || typeof(route.layout) === 'string' ? noProps : route.layout.props;
            
            let layout = layouts[layoutType];
            if (!layout)
                layout = layouts.default;

            return [layout, layoutProps]
        }

        public render() {
            const [Layout, props] = this.getLayout();

            return (
                <Suspense fallback={loading()}>
                    <Layout route={route} {...props}>
                        <WrappedComponent {...this.props} />
                    </Layout>
                </Suspense>
            );
        }
    }

    return withRouter(connect(
        (state: IReduxState) => ({ authenticated: state.auth.authenticated }),
        {}
    )(HOC));
};

export default withLayout;