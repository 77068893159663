// Quick guid not RFC 4122 compliant
export function generateGuid() {
    let result = '';

    for (let j = 0; j < 32; j++) {
        if (j === 8 || j === 12 || j === 16 || j === 20)
            result = result + '-';
        result = result + Math.floor(Math.random() * 16).toString(16).toUpperCase();
    }
    return result;
}