import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import auth from './auth/reducers';
import navigation from './navigation/reducers';
import notifications from './notifications/reducers';

export default (history: any) => combineReducers({
    auth,
    navigation,
    notifications,
    router: connectRouter(history),
});



