import * as c from "./constants";
import { ApiError } from "../../api/request/ApiError";

export type Level = 'danger' | 'warning' | 'info' | 'success';
export type GenericError = ApiError | Error | string | { message: string };

export interface INotification {
    id?: string;
    text: string;
    subText?: string;
    detail?: { [key: string]: string };
    icon?: string;
    bgColor?: string;
}

/*
 * Generic Notifications
*/
export interface IDispatchAddNotification {
    addNotification(msg: INotification): void;
}
export const addNotification = (msg: INotification) => ({
    payload: msg,
    type: c.NOTIFICATION_ADD,
});

export interface IDispatchRemoveNotification {
    removeNotification(id: string): void;
}
export const removeNotification = (id: string) => ({
    payload: id,
    type: c.NOTIFICATION_REMOVE,
});

export interface IDispatchClearNotifications {
    clearNotifications(): void;
}
export const clearNotifications = () => ({
    payload: null,
    type: c.NOTIFICATION_CLEAR,
});

/*
 * Manage exceptions 
 */
export interface IDispatchRaiseException {
    raiseException(error: GenericError, message?: string | null, level?: Level): void;
}

export const raiseException = (error: GenericError, message: string | null = null, level: Level = 'danger') => ({
    payload: { error, message, level },
    type: c.NOTIFICATION_EX_RAISE,
});

export interface IDispatchRaiseApiException {
    raiseApiException(error: ApiError);
}
export const raiseApiException = (error: ApiError) => ({
    payload: { error },
    type: c.NOTIFICATION_API_EX_RAISE,
});
export interface IDispatchCancelApiException {
    cancelApiException(error: ApiError);
}
export const cancelApiException = (error: ApiError) => ({
    payload: { error },
    type: c.NOTIFICATION_API_EX_CANCEL,
});