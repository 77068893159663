import React from "react";
import { IAppRoute } from "./types";
import { Redirect } from "react-router-dom";

const authRoute: IAppRoute = {
  allowAnonymous: true,
  name: "Auth",
  path: "/",

  children: [
    {
      allowAnonymous: true,
      component: React.lazy(() => import("../pages/auth/login")),
      layout: "auth",
      name: "Inloggen",
      path: "/auth/login",
    },
    {
      allowAnonymous: true,
      component: React.lazy(() => import("../pages/auth/recover-request")),
      layout: "recover",
      name: "Wachtwoord vergeten",
      path: "/auth/recover",
    },
    {
      allowAnonymous: true,
      component: React.lazy(() => import("../pages/auth/reset-password")),
      layout: "recover",
      name: "Wachtwoord vergeten",
      path: "/auth/reset/:token",
    },
  ],
};
const registerRoute: IAppRoute = {
  allowAnonymous: true,
  layout: "preauth",
  name: "Registreren",
  exact: true,
  path: "/register",
  component: React.lazy(() => import("../pages/register/index")),
  children: [
    {
      allowAnonymous: true,
      component: React.lazy(() => import("../pages/register/complete")),
      layout: "preauth",
      name: "Registreren",
      path: "/register/complete",
    },
  ],
};

const homeRoute: IAppRoute = {
  name: "Portal",
  path: "/home",
  exact: true,
  component: React.lazy(() => import("../pages/home/index")),
};
const callRoute: IAppRoute = {
  allowAnonymous: true,
  name: "Calls",
  path: "/call",

  children: [
    {
      name: "Call",
      path: "/call/:id",
      exact: true,
      component: React.lazy(() => import("../pages/call/index")),
    },
    {
      allowAnonymous: true,
      name: "Call",
      path: "/call/:id/preview",
      component: React.lazy(() => import("../pages/call/preview")),
    },
  ],
};
const applicationRoute: IAppRoute = {
  name: "Aanvragen",
  path: "/application",
  exact: true,
  component: () => <Redirect to="/account/applications" />,

  children: [
    {
      name: "Projectaanvraag",
      path: "/application/completed",
      component: React.lazy(() => import("../pages/application/completed")),
    },
    {
      name: "Projectaanvraag",
      path: "/application/:id",
      layout: "minimal",
      component: React.lazy(() => import("../pages/application/index")),
    },
  ],
};

const loanApplicationRoute: IAppRoute = {
  name: "Leningaanvragen",
  path: "/loan-application",
  exact: true,
  component: () => <Redirect to="/account/applications" />,

  children: [
    {
      name: "Leningaanvraag",
      path: "/loan-application/:id",
      layout: "minimal",
      component: React.lazy(() => import("../pages/loan-application/index")),
    },
  ],
};

const accountRoute: IAppRoute[] = [
  {
    name: "Mijn details",
    path: "/account/details",
    layout: {
      type: "default",
      props: { wrapClass: "layout-portal-mydetails" },
    },
    component: React.lazy(() => import("../pages/account/details")),
  },
  {
    name: "Mijn aanvragen",
    path: "/account/applications",
    layout: {
      type: "default",
      props: { wrapClass: "layout-portal-mydetails" },
    },
    component: React.lazy(() => import("../pages/account/applications")),
  },
];

export default [
  authRoute,
  registerRoute,
  ...accountRoute,
  applicationRoute,
  loanApplicationRoute,
  callRoute,
  homeRoute,
];
