import * as c from './constants';
import { ErrorReason } from './reducers';


export interface IAuthLogin {
    username: string;
    password: string;
    remember: boolean;
    isFromPrompt: boolean;
}
export const authLogin = (payload: IAuthLogin) => ({
    payload,
    type: c.AUTH_LOGIN,
});
export interface IDispatchAuthLogin {
    authLogin: (payload: IAuthLogin) => void;
}

export interface ILoginFromReset {
    token: string;
    navigate: boolean;
}
export const loginFromReset = (payload: ILoginFromReset) => ({
    payload,
    type: c.AUTH_LOGIN_FROM_RESET,
});
export interface IDispatchLoginFromReset {
    loginFromReset: (payload: ILoginFromReset) => void;
}

export interface IAuthSuccess {
    token: string;
    expiration: Date;
    name: string;
    roles: string[];
    userId: string;
}
export const authSuccess = (payload: IAuthSuccess) => ({
    payload,
    type: c.AUTH_SUCCESS,
});

export interface IAuthFailed {
    attemptsRemaining?: number;
    reason: ErrorReason;
    clearReason?: 'next' | 'now';
}
export const authFailed = (payload: IAuthFailed) => ({
    payload,
    type: c.AUTH_FAILED,
});


export interface IDispatchAuthLogout {
    authLogout: (payload?: IAuthLogout) => void;
}

export interface IAuthLogout {
    navigate?: boolean;
    keepUser?: boolean;
}
export const authLogout = (payload: IAuthLogout) => ({
    payload,
    type: c.AUTH_LOGOUT,
});

export interface IDispatchAuthClearError {
    authClearError: () => void;
}
export const authClearError = () => ({
    type: c.AUTH_CLEAR_ERROR
});

export interface IDispatchAuthReplaceToken {
    authReplaceToken: (arg: IAuthReplaceToken) => void;
}
export interface IAuthReplaceToken {
    token: string;
}
export const authReplaceToken = (payload: IAuthReplaceToken) => ({
    payload,
    type: c.AUTH_CLEAR_ERROR
});
