import React from 'react';
import { ApplicationInsights, Util } from '@microsoft/applicationinsights-web';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import { history } from '../redux/store';
import axios from 'axios';
import { generateGuid } from './generateGuid';
import generateMnemonic from './Bip39';

class ApplicationTracing {
    private readonly reactPlugin = new ReactPlugin();
    private appInsights: ApplicationInsights;

    public initialize() {
        if (!process.env.REACT_APP_APPINSIGHTS_INSTRUMENTATIONKEY)
            return;

        this.appInsights = new ApplicationInsights({
            config: {
                autoExceptionInstrumented: true,
                disableAjaxTracking: true,
                disableFetchTracking: true,
                enableAutoRouteTracking: true,
                extensionConfig: {
                    [this.reactPlugin.identifier]: { history }
                },
                extensions: [this.reactPlugin],
                instrumentationKey: process.env.REACT_APP_APPINSIGHTS_INSTRUMENTATIONKEY,
                isCookieUseDisabled: true,
            }
        });

        this.appInsights.loadAppInsights();

        axios.interceptors.request.use((defaultConfig) => ({
                ...defaultConfig,
                'Request-Id': Util.newId(),
                'Request-Context': 'appId=cid-v1:' + this.appInsights.context.appId(),
            }));
    }

    public trackComponent<P>(component: React.ComponentType<P>, name: string) {
        if (!this.appInsights)
            return component;

        return withAITracking(this.reactPlugin, component, name);
    }
    public trackRequest<T>(request: Promise<T>): Promise<T> {
        const appInsights = this.appInsights;
        if (!appInsights)
            return request;

        const start = new Date();

        return request.then((response: any) => {
            appInsights.trackDependencyData({
                duration: new Date().valueOf() - start.valueOf(),
                id: response.config.headers['Request-Id'],
                name: response.config.method + ' ' + response.config.url,
                responseCode: response.status,
                success: true,
                target: response.request.responseURL,
                type: 'fetch',
            });

            return response;
        }).catch((error) => {
            const response = error.response;
            if (response) {
                appInsights.trackDependencyData({
                    duration: new Date().valueOf() - start.valueOf(),
                    id: response.config.headers['Request-Id'],
                    name: response.config.method + ' ' + response.config.url,
                    responseCode: response.status,
                    success: false,
                    target: response.request.responseURL,
                    type: 'fetch',
                });
            }
            return Promise.reject(error);
        });
    }
   
    public trackException(error: Error, message: string, id?: string): string {
        if (!this.appInsights)
            return 'n/a';

        const mnemonicID = generateMnemonic(4);

        this.appInsights.trackException({
            exception: error,
            id: id || generateGuid(),
            properties: { 
                Message: message,
                ReferenceID: mnemonicID,
            },
            severityLevel: 3,
        });

        return mnemonicID;
    }

}

const instance = new ApplicationTracing();

export default instance;
