import { INotification, GenericError } from "./actions";
import * as c from './constants';

export interface INotificationState {
    lastError: GenericError,
    notifcations: INotification[],
}
export const initialState: INotificationState = {
    lastError: null,
    notifcations: [],
};

let idCounter: number = 1;

const Reducer = (state = initialState, action: any) => {
    switch (action.type) {

        case c.NOTIFICATION_ADD:
            const notification: INotification = action.payload;
            if (!notification.id)
                notification.id = (idCounter++).toString();

            return {
                ...state,
                notifcations: state.notifcations.concat([notification]),
            };

        case c.NOTIFICATION_CLEAR:
            return initialState;

        case c.NOTIFICATION_EX_RAISE:
            return {
                ...state,
                lastError: action.payload.error,
            };

        case c.NOTIFICATION_REMOVE:
            return {
                ...state,
                notifcations: state.notifcations.filter((f) => f.id !== action.payload),
            };

        default:
            return state;
    }
}

export default Reducer