import React from 'react';

import { Redirect, Route, RouteProps } from 'react-router-dom';

const PrivateRoute = (route: RouteProps & { authenticated: boolean }) => {
    const Component = route.component as any;
    const routeProps = {
        ...route, 
        component: undefined
    };
    return (
        <Route
            {...routeProps}
            render={(props) => {
                if (!route.authenticated) {
                    sessionStorage.setItem('pre-login-location', props.location.pathname + props.location.search);
                    return <Redirect to={{ pathname: '/auth/login', state: { from: props.location } }} />;
                }

                // authorised so return component
                return <Component {...props} />;
            }}
        />
    );
};

export default PrivateRoute;
