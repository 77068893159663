import * as c from './constants';
import * as authentication from '../../api/authentication';
import authenticationStore from './authenticationStore';

import { Action } from 'redux';
import { all, takeEvery, fork, put, call, takeLatest, race, take, select, delay } from 'redux-saga/effects';
import { IAuthLogin, authSuccess, authFailed, authLogout, IAuthLogout, ILoginFromReset } from './actions';
import { push } from 'connected-react-router';
import jwt_decode from 'jwt-decode';
import { raiseException } from '../notifications/actions';
import { IReduxState } from '../types';
import moment from 'moment';
import { ApiError } from '../../api/request/ApiError';
import axios from 'axios';

export interface IJwtToken {
  /** User ID */
  jti: string;
  /** Expiration */
  exp: number;
  /** Subject (user fullname) */
  sub: string; //
  "fno-callid": string | undefined;
}

function getExpires(token: IJwtToken): Date {
  return moment.unix(token.exp).utc(false).toDate();
}

function readToken(token: string, ignoreInvalid: boolean = false): IJwtToken {
  try {
    return jwt_decode<IJwtToken>(token);
  } catch (e) {
    if (ignoreInvalid) return null;
    throw new ApiError("Ongeldig Token", false, e.message || JSON.stringify(e));
  }
}
function* authenticationSuccess(token: string, navigate: boolean) {
  const decoded = readToken(token);
  console.log("callid in authenticationSuccess: ", decoded["fno-callid"]);

  try {
    yield put(
      authSuccess({
        expiration: getExpires(decoded),
        name: decoded.sub,
        roles: [],
        token,
        userId: decoded.jti,
      })
    );

    axios.defaults.headers.common.Authorization = "Bearer " + token;
    authenticationStore.sessionToken = token;

    if (navigate !== true) return;

    const callId = decoded["fno-callid"];
    const location = authenticationStore.preLoginLocation;
    if (!callId) {
      // No callId found, so just navigate to the home page
      if (location) {
        authenticationStore.preLoginLocation = null;
        if (location.startsWith("/auth")) {
          yield put(push("/home"));
        } else {
          yield put(push(location));
        }
      } else {
        yield put(push("/home"));
      }
    } else {
      // callId found, so navigate to the loan page
      if (location) {
        if (location.startsWith("/auth") || location.startsWith("/home")) {
          yield put(push(`/call/${callId}`));
        } else {
          yield put(push(location));
        }
      } else {
        yield put(push(`/call/${callId}`));
      }
    }
  } catch (e) {
    yield put(
      raiseException(
        e,
        "Er is een fout opgetreden tijdens het inloggen",
        "danger"
      )
    );
  }
}
function* startLogin(action: any) {
    const payload: IAuthLogin = action.payload;
    const isFromPrompt = payload && payload.isFromPrompt;
    try {
        const login: authentication.ILoginResult = yield call(authentication.login, payload.username, payload.password, { raiseException: 'never' });
        if (login.result !== 'Success') {
            yield put(authFailed({ reason: login.result, attemptsRemaining: login.attemptsRemaining, clearReason: isFromPrompt ? 'now' : 'next' }));
            return;
        }
        if (payload.remember) {
            authenticationStore.cachedToken = login.token;
            authenticationStore.cachedUsername = payload.username;
        }
        else {
            authenticationStore.cachedToken = null;
            authenticationStore.cachedUsername = null;
        }

        yield call(authenticationSuccess, login.token, true);
    }
    catch (e) {
        yield put(authFailed({ reason: 'exception', clearReason: isFromPrompt ? 'now' : 'next' }));
        yield put(raiseException(e, 'Error during authentication', 'danger'));
    }
}

function* autoLogin() {
    try {
        const token = authenticationStore.sessionToken || authenticationStore.cachedToken;
        if (token) {
            const decoded = readToken(token, true);

            console.debug(`Auto Login: ${getExpires(decoded)} - ${new Date()}`);
            if (getExpires(decoded) > new Date()) {

                yield call(authenticationSuccess, token, true);
                return;
            }

            const response: authentication.IRefreshTokenResult = yield call(authentication.refreshToken, token);

            if (response.result) {
                authenticationStore.cachedToken = response.token;
                yield call(authenticationSuccess, response.token, true);
                return;
            }
            else {
                authenticationStore.cachedToken = null;
            }
        }
    }
    catch (e) {
        authenticationStore.cachedToken = null;
    }

    yield put(authLogout({ navigate: false, keepUser: true }));
}
function* logout(action: Action & { payload: IAuthLogout }) {
    authenticationStore.sessionToken = null;
    authenticationStore.cachedToken = null;
    authenticationStore.preLoginLocation = null;

    const payload = action.payload || {};

    if (!payload.keepUser)
        authenticationStore.cachedUsername = null;

    if (!payload || payload.navigate !== false) {
        authenticationStore.preLoginLocation = yield select((state: IReduxState) => state.router.location.pathname);
        yield put(push('/auth/login'));
    }
}
function* tokenRefresh(refreshWindow: number = 15) {
    const expires: Date = yield select((state: IReduxState) => state.auth.tokenExpiration);
    if (!expires)
        return;
    const remaining = moment(expires).diff(moment().add(refreshWindow, 'minutes'), 'milliseconds');
    const result = yield race([
        take(c.AUTH_LOGOUT),
        take(c.AUTH_FAILED),
        delay(remaining)
    ]);

    if (result[2]) {
        const token: string = yield select((state: IReduxState) => state.auth.token);
        if (!token)
            return;

        try {
            const response: authentication.IRefreshTokenResult = yield call(authentication.refreshToken, token);

            if (response.result) {
                yield call(authenticationSuccess, response.token, false);
                return;
            }
        }
        catch (e) {
            console.error(e);
            yield put(raiseException(e, 'Failed to refresh the login token', 'warning'));

            if (refreshWindow > 2) {
                yield call(tokenRefresh, refreshWindow - 2);

                return;
            }
        }

        yield put(authFailed({ reason: 'expired', clearReason: 'next' }));
    }
}

function* loginFromReset(action: Action & { payload: ILoginFromReset }) {
    try {
        yield call(authenticationSuccess, action.payload.token, action.payload.navigate);
    }
    catch (e) {
        put(authFailed({ reason: 'exception' }));
        yield put(raiseException(e, 'Error during authentication', 'danger'));
    }
}

function* authSaga() {
    yield all([
        fork(function*() { yield takeEvery(c.AUTH_LOGIN, startLogin) }),
        fork(function*() { yield takeEvery(c.AUTH_AUTO_LOGIN, autoLogin) }),
        fork(function*() { yield takeEvery(c.AUTH_LOGOUT, logout) }),
        fork(function*() { yield takeLatest(c.AUTH_SUCCESS, () => tokenRefresh(15)) }),
        fork(function*() { yield takeEvery(c.AUTH_LOGIN_FROM_RESET, loginFromReset) }),
    ]);
}

export default authSaga;
