import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore, Store } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { IReduxState } from './types';
import createRootReducer from './reducers';
import sagas from './sagas';
import { AUTH_AUTO_LOGIN } from './auth/constants';
import moment from 'moment';
import moment_nl from 'moment/locale/nl';

export const history = createBrowserHistory();

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware, routerMiddleware(history)];

const current = { store: null };
export function getCurrentStore(): Store<IReduxState>  {
    return hold.getCurrentStore();
}
class StoreHolder {
    public getCurrentStore(): Store<IReduxState>  {
        return current.store;
    }
}
const hold = new StoreHolder();

export function configureStore(initialState?: IReduxState) {
    const w = window as any;
    const composeEnhancers = (w.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && w.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true, traceLimit: 25 })) || compose;

    const store = createStore(
        createRootReducer(history),
        initialState,
        composeEnhancers(applyMiddleware(...middlewares))
    );

    current.store = store;

    sagaMiddleware
        .run(sagas, null)
        .toPromise()
        .catch((error: any) => {
            alert('Root saga exception: ' + error.message || JSON.stringify(error));
        });
    
    // updateLocale has to be used even though 'nl' is not yet defined. Using defineLocale will result in a warning
    // see https://momentjs.com/guides/#/warnings/define-locale/
    moment.updateLocale('nl', moment_nl);
    moment.locale('nl');
    
    store.dispatch({ type: AUTH_AUTO_LOGIN })

    return store;
}