import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { configureStore } from './redux/store';
import applicationInsights from './global/applicationInsights'

import axios from "axios";
import { getCurrentStore } from "./redux/store";
import { authFailed } from "./redux/auth/actions";

(function initialize() {
  if (!process.env.REACT_APP_BACKEND_URL) {
    console.warn("Global configuration not available");
    throw new Error("Global configuration not available");
  }
  console.info(
    "initializing... backend url:",
    process.env.REACT_APP_BACKEND_URL
  );
  axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL;
  axios.interceptors.response.use(undefined, (result) => {
    if (result && result.response && result.response.status === 401) {
      getCurrentStore().dispatch(
        authFailed({ reason: "exception", clearReason: "next" })
      );
    }
    return result;
  });
})();

const store = configureStore();
applicationInsights.initialize();

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
