import React from 'react';
import { connect } from "react-redux";
import { IReduxState } from "../redux/types";
import { IDispatchClearNotifications, INotification, clearNotifications } from '../redux/notifications/actions';
import './ErrorDisplay.scss';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, ListGroup } from 'reactstrap';

interface IReduxProps {
    notifications: INotification[],
}
interface IReduxDispatch extends IDispatchClearNotifications {
}
interface IProps extends IReduxProps, IReduxDispatch { }

interface IState {
    expanded: boolean;
}
class Errors extends React.PureComponent<IProps, IState> {

    constructor(props) {
        super(props);
        this.state = {
            expanded: false
        };
    }
    public clear() {
        this.setState({ expanded: false });
        this.props.clearNotifications();
    }

    public render() {
        if (this.props.notifications.length === 0)
            return null;

        return <>
            <div className="errorNotification" onClick={() => this.setState({ expanded: !this.state.expanded })}>{this.props.notifications.length}</div>

            {<Modal isOpen={this.state.expanded} size="lg">
                <ModalHeader toggle={() => this.setState({ expanded: false })}>Meldingen</ModalHeader>
                <ModalBody>
                    <ListGroup>
                        {this.props.notifications.map((item, index) => <React.Fragment key={item.id || index}>
                            {item.text}<br />
                            {item.subText && <>{item.subText}<br /></>}
                            {item.detail && <dl>
                                {Object.keys(item.detail).map((key) => <React.Fragment key={key}>
                                    <dt>{key}</dt>
                                    <dd>{item.detail[key] && item.detail[key].length > 100 ? <div className="long">{item.detail[key]}</div> : item.detail[key]} </dd>
                                </React.Fragment>)}
                            </dl>}
                        </React.Fragment>)}
                    </ListGroup>
                </ModalBody>
                <ModalFooter>
                    <Button size="sm" color="default" onClick={() => this.clear()}>Wissen</Button>
                    <Button size="sm" color="default" onClick={() => this.setState({ expanded: false })}>Sluiten</Button>
                </ModalFooter>
            </Modal>}
        </>;
    }
}

export default connect(
    (state: IReduxState) => ({
        notifications: state.notifications.notifcations,
    }),
    { clearNotifications }
)(Errors);