import React, { Component } from 'react';
import './styling/root.scss';
import { ConnectedRouter } from 'connected-react-router';
import { history } from './redux/store';

import Routes from './route/Routes';
import ErrorDisplay from './components/ErrorDisplay';

class App extends Component {

    constructor(props: any) {
        super(props);

        this.state = { error: null };
    }
    componentDidMount() {
    }

    render() {
        return <>
            <ErrorDisplay />
            <ConnectedRouter history={history}>
                <div className="App">
                    <Routes />
                </div>
            </ConnectedRouter>
        </>;
    }
}

export default App;
