import { all, delay, fork, put, race, take, takeEvery } from 'redux-saga/effects';

import { addNotification, raiseException } from './actions';
import { NOTIFICATION_API_EX_CANCEL, NOTIFICATION_API_EX_RAISE, NOTIFICATION_EX_RAISE } from './constants';

import { ApiError } from '../../api/request/ApiError';

import applicationInsights from '../../global/applicationInsights';
import { generateGuid } from '../../global/generateGuid';

function* exceptionNotification(action) {
    const { error, level } = action.payload;
    let message = action.payload.message;

    const id = generateGuid();

    const reference = error.reference ? error.reference : applicationInsights.trackException(error, message, id);

    const detail: { [key: string]: string } = {};
    detail.Reference = reference;
    detail.Time = new Date().toLocaleString();

    if (typeof error === 'object') {
        // stringify does not work on Error objects
        if (error.stack) {
            detail.Message = error.message;
            detail.Stack = error.stack;
        }
        else if (error instanceof ApiError) {
            if (error.isUserError)
                detail.Detail = error.detail;
            else
                detail.Json = error.detail;
        } else {
            detail.Json = JSON.stringify(error);
        }
    }
    else if (error.toString) {
        detail.Message = error.toString();
    }
    else {
        detail.Json = JSON.stringify(error);
    }

    if (!message && error.message)
        message = error.message;
    console.warn('Captured exception: ' + message);
    console.info(detail);
    yield put(addNotification({
        bgColor: typeof level === 'string' ? level : error.isUserError ? 'warning' : 'danger',
        detail,
        icon: ' mdi mdi-alpha-x-circle',
        id,
        subText: new Date().toLocaleTimeString(),
        text: message,
    }));
}

function* raiseApiException(action) {
    // wait for either the exception to be raised using the normal handler or to be cancelled (both signifying the 
    // error is being handled.
    // Only raise the error if this does not happen

    const [timeout] = yield race([
        delay(500),
        take((next) => (next.type === NOTIFICATION_API_EX_CANCEL || next.type === NOTIFICATION_EX_RAISE) && next.payload.error === action.payload),
    ]);

    if (timeout) {
        yield put(raiseException(action.payload, action.payload.message));
    }
}

export function* watchException(): any {
    yield takeEvery(NOTIFICATION_EX_RAISE, exceptionNotification);
}
export function* watchApiException(): any {
    yield takeEvery(NOTIFICATION_API_EX_RAISE, raiseApiException);
}

function* notificationSaga(): any {
    yield all([
        fork(watchException),
        fork(watchApiException),
    ]);
}

export default notificationSaga;
