export class ApiError {
    constructor(message: string, isUserError: boolean, detail?: string, isTransient?: boolean, fieldErrors?: Array<{ field: string, messages: string[] }>) {
        this.data = { detail, isTransient, isUserError, message, fieldErrors };
    }
    private readonly data: { message: string, isUserError: boolean, detail?: string, isTransient?: boolean, fieldErrors: Array<{ field: string, messages: string[] }> };

    public get isUserError(): boolean { return this.data.isUserError; }
    public get isTransient(): boolean { return this.data.isTransient; }
    public get message(): string { return this.data.message; }
    public get detail(): string { return this.data.detail; }
    public get fieldErrors(): Array<{ field: string, messages: string[] }> { return this.data.fieldErrors && this.data.fieldErrors.map((x) => ({ field: x.field, messages: x.messages.map((v) => v) })); }

    /**
     * For non user errors raises the exception and replaces the it with a generic message.
     * @param e Error object to check
     * @param raise raiseException redux method; called if e is not a user error
     * @param userMessage user friendly error message
     */
    public static raiseOrUser(e: any, raise: (error: Error, message: string | null) => void, userMessage?: string): any;
    public static raiseOrUser(e: string, raise: (error: Error, message: string | null) => void): any;
    public static raiseOrUser(e: any, raise: (error: Error, message: string | null) => void, userMessage?: string): any {
        if (e instanceof ApiError && e.isUserError) {
            return e;
        }
        
        console.error(e);

        if (typeof(e) === 'string') {
            raise(new Error(e), userMessage || e);
            return userMessage || e;
        }
        else {
            if (!userMessage)
                userMessage = 'Er is een onverwachte fout opgetreden';
            raise(e, userMessage);
            return userMessage;
        }
    }
}
