import requestWrapper, { ICallerOptions } from "./request";
import axios from "axios";
import { IListOption } from "../components/form/types";
import { withCache } from "./request/cache";
import { Guid, IOrganisationDetails, IPersonalDetails } from "./types";

const basePath = '/api/authentication/';

interface ILoginSuccessResult {
    result: 'Success';
    token: string;
}
interface ILoginFailedResult {
    attemptsRemaining?: number;
    result: 'InvalidCredential' | 'AttemptsExceeded';
}
export type ILoginResult = ILoginSuccessResult | ILoginFailedResult;

export function login(username: string, password: string, options?: ICallerOptions): Promise<ILoginResult> {
    return requestWrapper(() => axios.post(basePath + 'login', { username, password }), options);
}

export interface IRefreshTokenResult {
    result: boolean;
    token: string;
}
export function refreshToken(token: string, options?: ICallerOptions): Promise<IRefreshTokenResult> {
    return requestWrapper(() => axios.get(basePath + 'refresh', { params: { token } }), options);
}

export interface IRegistrationOptions {
    completedMessage: string;
    genderCodes: IListOption<number>[];
    legalForms: IListOption<number>[];
    organizationTypes: IListOption<Guid>[];
}


export const registrationOptions = withCache((options?: ICallerOptions): Promise<IRegistrationOptions> => {
    return requestWrapper(() => axios.get(basePath + 'create'), options);
});


export interface ICreateAccountRequest {
    organization: IOrganisationDetails;
    personal: IPersonalDetails;
    callId: string | undefined;
}
export interface ICreateAccountReponse {
    result: 'Success' | 'ExistingEmailAddress';
}
export function createAccount(request: ICreateAccountRequest, options?: ICallerOptions): Promise<ICreateAccountReponse> {
    return requestWrapper(() => axios.put(basePath + 'create', request), options);
}

export interface IRequestResetResponse {
    completedMessage: string;
}
export function requestReset(username: string, options?: ICallerOptions): Promise<IRequestResetResponse> {
    return requestWrapper(() => axios.post(basePath + 'requestreset', { username }), options);
}

export interface IResetPasswordResponse {
    result: 'Success' | 'InvalidToken' | 'TokenExpired' | 'NewPasswordCriteria';
    token: string;
}
export function resetPassword(username: string, newPassword: string, resetToken: string, options?: ICallerOptions): Promise<IResetPasswordResponse> {
    return requestWrapper(() => axios.post(basePath + 'reset', { username, newPassword, resetToken }), options);
}