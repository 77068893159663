
function setOrRemove(key: string, value: string, storage: Storage = localStorage) {
    if (value)
        storage.setItem(key, value);
    else
        storage.removeItem(key);
}

export class LocalAuthenticationStore {
    public get preLoginLocation(): string {
        return sessionStorage.getItem('pre-login-location');
    }
    public set preLoginLocation(value: string) {
        setOrRemove('pre-login-location', value, sessionStorage);
    }
    public get sessionToken(): string {
        return sessionStorage.getItem('cached-token');
    }
    public set sessionToken(value: string) {
        setOrRemove('cached-token', value, sessionStorage);
    }

    public get cachedToken(): string {
        return localStorage.getItem('cached-token');
    }
    public set cachedToken(value: string) {
        setOrRemove('cached-token', value);
    }
    public get cachedUsername(): string {
        return localStorage.getItem('cached-username');
    }
    public set cachedUsername(value: string) {
        setOrRemove('cached-username', value);
    }
}

const authenticationStore = new LocalAuthenticationStore();
export default authenticationStore;