import * as c from './constants';
import { IAuthSuccess, IAuthFailed } from './actions';

const maxAttempts = 5;

export type ErrorReason = 'exception' | 'expired' | 'InvalidCredential' | 'AttemptsExceeded';
export interface IAuthState {
    authenticated: boolean;
    autoLoginComplete: boolean;
    status: 'idle' | 'busy' | 'error';
    errorReason: ErrorReason;
    errorReasonClear: null | 'next' | 'now';
    attemptsRemaining: number;

    userId: string;
    userName: string;
    token: string;
    tokenExpiration: Date;
    userRoles: [];
}
export const initialState: IAuthState = {
    authenticated: false,
    autoLoginComplete: false,
    errorReason: null,
    errorReasonClear: null,
    status: 'idle',
    attemptsRemaining: maxAttempts,

    token: null,
    tokenExpiration: new Date(0),
    userId: null,
    userName: null,
    userRoles: [],
};

const Reducer = (state = initialState, action: any) => {
    switch (action.type) {
        case c.AUTH_LOGIN: {
            return { ...state, status: 'busy' };
        }

        case c.AUTH_SUCCESS: {
            const { token, expiration, name, roles, userId }: IAuthSuccess = action.payload;
            return {
                ...state,
                attemptsRemaining: maxAttempts,
                authenticated: true,
                autoLoginComplete: true,
                status: 'idle',
                token,
                tokenExpiration: expiration,
                userId,
                userName: name,
                userRoles: roles,
            };
        }

        case c.AUTH_FAILED: {
            const { reason, attemptsRemaining, clearReason }: IAuthFailed = action.payload;
            return {
                ...state,
                attemptsRemaining: typeof (attemptsRemaining) === 'number' ? attemptsRemaining : (state.attemptsRemaining - 1),
                authenticated: false,
                autoLoginComplete: true,
                errorReason: reason,
                errorReasonClear: clearReason || 'next',
                status: 'error',

                token: null,
                tokenExpiration: null,
                userId: null,
                userRoles: []
            };
        }

        case c.AUTH_LOGOUT: {
            return {
                ...state,

                attemptsRemaining: maxAttempts,
                authenticated: false,
                autoLoginComplete: true,
                errorReason: null,

                status: 'idle',
                token: null,
                tokenExpiration: null,
                userId: null,
                userRoles: []
            };
        }
        case c.AUTH_AUTO_LOGIN: {
            return { 
                ...state,
                
                status: 'busy',
            };
        }
        case c.AUTH_CLEAR_ERROR: {
            return {
                ...state,
                errorReason: state.errorReasonClear === 'now' ? null : state.errorReason,
                errorReasonClear: state.errorReasonClear ? state.errorReasonClear === 'now' ? null : 'now' : null,
                status: 'idle',
            };
        }

        default:
            return state;
    }
}

export default Reducer