const cacheRegistry: { [key: string]: { clear: () => void } } = {};
const activeCaches: string[] = [];

function updateCache(size: number, clear: () => void) {
    const key: string = Math.random().toString();
    cacheRegistry[key] = { clear };

    return key;
}
function tickleCache(key: string, size?: number) {
    const current = activeCaches.indexOf(key);
    if (current >= 0)
        delete activeCaches[current];
    activeCaches.push(key);

    while (activeCaches.length > 10) {
        cacheRegistry[activeCaches[0]].clear();

        delete activeCaches[0];
    }
}

export function withCache<T extends () => Promise<any>>(load: T): T {
    const cache: { value: any } = { value: null };
    const key = updateCache(0, () => cache.value = null);

    // The resolve queue holds additional request and resolves these when the first call completes
    let resolveQueue: Array<{ resolve: (x: any) => void, reject: (e: any) => void }> = null;

    return (() => {
        if (cache.value)
            return Promise.resolve(cache.value);
        if (resolveQueue)
            return new Promise((resolve, reject) => resolveQueue.push({ resolve, reject }));

        resolveQueue = [];

        return load()
            .then((data) => {
                tickleCache(key)
                cache.value = data;
                while (resolveQueue.length > 0)
                    resolveQueue.pop().resolve(data);
                resolveQueue = null;
                return data;
            }, (reason) => {
                while (resolveQueue.length > 0)
                    resolveQueue.pop().reject(reason);
                resolveQueue = null;
                return Promise.reject(reason);
            });
    }) as T;
}

export function withCacheItem<TD, T extends (...args: any[]) => Promise<any>>(getKey: (...args: any[]) => string, load: T): T {
    const cache: { [key: string]: any } = {};
    const key = updateCache(0, () => cache.value = null);
    const resolveQueues: { [key: string]: Array<{ resolve: (x: any) => void, reject: (e: any) => void }> } = {};

    return ((...args: any[]) => {
        const itemKey = getKey.apply(this, args);

        if (cache[itemKey])
            return Promise.resolve(cache[itemKey]);

        {
            const resolveQueue = resolveQueues[itemKey];
            if (resolveQueue)
                return new Promise((resolve, reject) => resolveQueue.push({ resolve, reject }));
        }
        
        resolveQueues[itemKey] = [];

        return load.apply(this, args)
            .then((data) => {
                tickleCache(key)
                cache[itemKey] = data;

                const resolveQueue = resolveQueues[itemKey];

                while (resolveQueue.length > 0)
                    resolveQueue.pop().resolve(data);
                delete resolveQueues[itemKey];

                return data;
            }, (reason) => {
                const resolveQueue = resolveQueues[itemKey];

                while (resolveQueue.length > 0)
                    resolveQueue.pop().reject(reason);
                delete resolveQueues[itemKey];
            });
    }) as T;
}