import { IAppRoute } from '../../route/types';
export function flattenRoutes(routes: IAppRoute[]): IAppRoute[] {
    let flatRoutes: IAppRoute[] = [];
    routes = routes || [];
    routes.forEach((item) => {
        flatRoutes.push(item);
        if (typeof item.children !== 'undefined') {
            flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
        }
    });
    return flatRoutes;
}
